import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Layout from '../../../components/v2/Layout';
import CustomAdd from '../../../components/CustomAdd';
import { Box, CardContent, Paper } from '@material-ui/core';
import { getEmoriRoomList } from '../../../actions/emori';
import { createEmoriRoomsSelector } from '../../../reducers/emori';

const newRoom = {
  'id': 0,
  'name': '',
  'icon': 'addNewCard',
  'path': () => navigate('/emori/relation/createRoom/'),
  'admin': '',
}

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '15px',
    backgroundColor: 'white',
    border: '1px dashed white',
    justifyContent: 'center',
    height: '100%',
  },
  categoryName: {
    marginTop: 5,
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 1,
  },
  boxRoom: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    width: '60%',
  }
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rooms = useSelector(createEmoriRoomsSelector);

  React.useEffect(() => {
    dispatch(getEmoriRoomList());
  }, []);

  const clickRoom = (roomId) => {
    navigate(`/emori/relation/room?id=${roomId}`)
  }

  return (
    // loading in layout
    <Layout bg='bg3' title='รายการห้อง'>
      <Typography variant='h5' component='h5' style={{ marginBottom: '15px', fontWeight: 600, color: 'white' }}>ห้องของคุณ</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3} key={newRoom.id}>
          <Paper elevation={3} className={classes.card} style={{ backgroundColor: 'unset' }} onClick={() => newRoom.path()}>
            <Box className={classes.boxRoom}>
              <img src={require(`../../../assets/images/${newRoom.icon}.svg`)} />
            </Box>
          </Paper>
        </Grid>
        {
          rooms.map(room => (
            <Grid item xs={6} sm={6} md={3} key={room.id}>
              <Paper elevation={3} className={classes.card} onClick={() => clickRoom(room.id)}>
                <Box className={classes.boxRoom}>
                  <img src={room.icon.image} className={classes.icon} />
                  <Typography variant='subtitle1' component='p' color='textPrimary' className={classes.categoryName}>
                    {room.name}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </Layout>
  );
};
